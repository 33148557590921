import React, {useEffect, useRef, useState} from 'react';
import {TextButton, TextButtonPriority} from 'wix-ui-tpa';
import classNames from 'classnames';
import s from './Clamp.scss';

export enum ClampDataHook {
  ReadMoreButton = 'ClampDataHook.ReadMoreButton',
  ReadLessButton = 'ClampDataHook.ReadLessButton',
}

export type ClampProps = {
  enabled: boolean;
  className: string;
  readMoreLessLinkClassName: string;
  readMoreText: string;
  readLessText: string;
  dataHook?: string;
};

export const Clamp: React.FunctionComponent<ClampProps> = ({
  children,
  className,
  readMoreLessLinkClassName,
  readMoreText,
  readLessText,
  enabled,
  dataHook,
}) => {
  const [isClamped, setIsClamped] = useState(true);
  const [shouldShowReadMoreLessLink, setShouldShowReadMoreLessLink] = useState(false);
  const ref = useRef<any>(null);

  useEffect(() => {
    setShouldShowReadMoreLessLink(ref.current?.scrollHeight > ref.current?.clientHeight);
  }, [ref]);

  // istanbul ignore next: cant test with jsdom, will be tested by sled
  const readMoreLink = () => (
    <TextButton
      className={readMoreLessLinkClassName}
      priority={TextButtonPriority.link}
      onClick={() => setIsClamped(!isClamped)}
      data-hook={isClamped ? ClampDataHook.ReadMoreButton : ClampDataHook.ReadLessButton}>
      {isClamped ? readMoreText : readLessText}
    </TextButton>
  );

  const nonClampedHtml = (
    <div className={classNames(s.textContainer, className)} data-hook={dataHook}>
      {children}
    </div>
  );
  const clampedHtml = (
    <>
      <div className={classNames(s.textContainer, className, {[s.clamp]: isClamped})} ref={ref} data-hook={dataHook}>
        {children}
      </div>
      {shouldShowReadMoreLessLink &&
        /*istanbul ignore next: cant test with jsdom, will be tested by sled*/ readMoreLink()}
    </>
  );

  return enabled ? clampedHtml : nonClampedHtml;
};
